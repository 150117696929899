/* Neighborhood Urgent Care Clinic Website Stylesheet */

@import url('https://fonts.googleapis.com/css?family=Rubik:400,500,700&display=swap');

$color-red-dark: rgb(151, 37, 37);
$color-red: #c73231;
$color-red-light: #44afff;
$color-gray-light: #c4c4c4;
$color-gray: #7f7f7f;
$color-gray-dark: #333333;
$color-offwhite: #f8f9fa;
$color-white: #fff;

:root {
  --font-size-md: 18px;
  --jumbotron-padding-y: 3rem;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  background-color: $color-white;
  font-family: 'Rubik', sans-serif;
  min-width: 320px;
  margin: 0;
  margin-bottom: 60px; /* Margin bottom by footer height */
}

.app-container {
  padding: 80px 0 180px 0;
}

.navbar {
  border-bottom: 1px solid #e5e5e5;
}
.navbar {
  background-color: $color-red;
}

.section {
  margin-bottom: 2.5rem;
  h3,
  h5 {
    margin-bottom: 12px;
  }
  .btn {
    margin-top: 15px;
  }
}

.location-info {
  hr {
    display: none;
  }
}

.services-info > button {
  margin-top: 10px;
}

.home-header {
  img {
    height: auto;
    width: 90%;
  }
  border-radius: 0;
  margin-bottom: 3rem;
  text-align: center;
}

.footer {
  background-color: $color-red-dark;
  bottom: 0;
  color: white;
  line-height: 40px;
  padding: 20px 0;
  position: absolute;
  width: 100%;
  a,
  b,
  p {
    color: $color-offwhite;
  }
  a {
    font-size: 16px;
    line-height: initial;
    :hover {
      text-decoration: underline;
    }
  }
  p {
    margin: 0;
    padding: 0;
    line-height: initial;
  }
}

h1,
h2,
h3,
h4,
h5,
p {
  margin-bottom: 5px;
  overflow-wrap: break-word;
}

a {
  color: #222222;
  font-size: var(--font-size-md);
  text-decoration: none;
  transition: all 0.2s ease;
  padding-bottom: 7px;
}
a:hover {
  color: black;
}

.navbar-brand {
  width: 150px;
}

.navbar-dark .navbar-toggler {
  border-style: none;
}

.app-container {
  font-size: 16px;
  margin: 40px auto;
}

button,
.btn {
  background-color: $color-red;
  border-radius: 1px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 15px 20px;
  transition: all 0.2s ease;
  &:hover {
    background-color: lighten($color-red, 5%);
  }
  &:active {
    background-color: lighten($color-red, 5%);
  }
  &:disabled {
    background-color: lighten($color-red, 20%);
    cursor: not-allowed;
  }
}

form {
  max-width: 300px;
  margin: 0 auto;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}
.form-group label {
  font-size: 18px;
  margin-bottom: 10px;
}
.form-group input,
.form-group textarea {
  border-radius: 1px;
  border-color: darkgray;
  border-style: solid;
  border-width: 1px;
  font-size: 18px;
  padding: 10px 12px;
}

@media only screen and (max-width: 750px) {
  .col-hide-sm {
    display: none;
  }
  .app-container {
    padding: 80px 0 40px 0;
  }
  .location-info {
    hr {
      display: block;
    }
  }
  .section {
    margin-bottom: 1rem;
  }
  .home-header {
    img {
      width: 100%;
    }
  }
}
